import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Protector from './components/Protector'

import { genrateToken, messaging } from './Firebase'
import { onMessage } from 'firebase/messaging'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const EmailLogin = React.lazy(() => import('./views/pages/login/EmailLogin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const SocailLogin = React.lazy(() => import('./views/pages/login/Social_Login'))

const App = () => {
  const [notificationSent, setNotificationSent] = useState(false)

  useEffect(() => {
    genrateToken()
    onMessage(messaging, (payload) => {
      console.log(payload)
      if (!notificationSent && Notification.permission === 'granted') {
        toast.info(
          <div>
            {/* <img
              src={payload.notification.image}
              alt="Notification"
              style={{ width: '50px', height: '50px', marginRight: '10px' }}
            /> */}
            <div className="mx-3">
              <h3>{payload.notification.title}</h3>
              <p>{payload.notification.body}</p>
            </div>
          </div>,
          {
            autoClose: false, // Disable auto close
          },
        )
        new Notification(payload.notification.title, { body: payload.notification.body })
        setNotificationSent(true)
      }
    })
  }, [notificationSent])
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/login_email" name="Login Page" element={<EmailLogin />} />
          <Route exact path="/Social_login" name="Login Page" element={<SocailLogin />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<Protector Component={DefaultLayout} />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
