import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function Protector(props) {
  const navigate = useNavigate()
  // useEffect(() => {
  //   const isLoggedIn = localStorage.getItem('key')
  //   if (isLoggedIn === '' || isLoggedIn === null || isLoggedIn === undefined) {
  //     navigate('/login')
  //   }
  // }, [navigate])

  useEffect(() => {
    const storedUser = localStorage.getItem('key')
    const storedUserRole = localStorage.getItem('user_role')
    const isLoggedIn = localStorage.getItem('key')

    if (isLoggedIn === '' || isLoggedIn === null || isLoggedIn === undefined) {
      navigate('/login')
    }

    const fetchUserDetails = async (id) => {
      const params = {
        user_id: id,
      }
      const res = await axios.post(process.env.REACT_APP_API_URL + '/auth/checkUserData', params)
      const response = res.data
      if (response.status === 'success') {
        if (
          response.userDetails.user_role !== storedUserRole ||
          response.userDetails.banned === 'Y'
        ) {
          localStorage.removeItem('key')
          localStorage.removeItem('user_role')
          navigate('/login')
        }
      } else {
        localStorage.removeItem('key')
        localStorage.removeItem('user_role')
        navigate('/login')
      }
    }

    if (storedUser !== '' || storedUser !== null) {
      fetchUserDetails(storedUser)
    }
  }, [navigate])
  // eslint-disable-next-line react/prop-types
  const Component = props.Component
  return (
    <div>
      <Component></Component>
    </div>
  )
}
