import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getMessaging, getToken } from 'firebase/messaging'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDIaLltiHhyt2LjsrRlx9rugSfr25TugOg',
  authDomain: 'phone-auth-2aa4d.firebaseapp.com',
  projectId: 'phone-auth-2aa4d',
  storageBucket: 'phone-auth-2aa4d.appspot.com',
  messagingSenderId: '50080235620',
  appId: '1:50080235620:web:cb4f9e197808a3e779df71',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)
const messaging = getMessaging(app)

const genrateToken = async () => {
  const permission = await Notification.requestPermission()
  console.log(permission)
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey:
        'BP5V0iH_Dw1jPbg4XjD5P9mm2x6Op3DmT-1YzLIoaKhR0bMewvKYjxdZFsjO3AMxCpy23b2MNj7J4qn4zrONi8U',
    })

    //We can send token to server
    console.log('Token generated : ', token)
  } else if (permission === 'denied') {
    //notifications are blocked
    alert('You denied for the notification')
  }
}

export { auth, genrateToken, messaging }
